import React, { useState } from 'react';
import '../css/common.css';
import '../css/aboutus.css';
import op1 from '../asset/op1.png';
import op2 from '../asset/op2.png';
import op3 from '../asset/op3.png';
import op4 from '../asset/op4.png';
import mp1 from '../asset/m_p_1.png';
import mp2 from '../asset/m_p_2.png';
import mp3 from '../asset/m_p_3.png';
import mt1 from '../asset/m_t_1.png';
import mt2 from '../asset/m_t_2.png';
import mt3 from '../asset/m_t_3.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function OurProduct() {
  const [showAboutUs, setShowAboutUs] = useState(true);
  const [showAboutUss, setShowAboutUss] = useState(false);

  const handleShowAboutUs = () => {
    setShowAboutUs(true);
    setShowAboutUss(false);
  };

  const handleShowAboutUss = () => {
    setShowAboutUs(false);
    setShowAboutUss(true);
  };

  return (
    <>
      <div className='ourproductbanner'>
        <div style={{color:"white", margin:"auto", fontSize:"2rem", fontWeight:"bold", zIndex:"2"}}>Our Product</div>
      </div>

      <div className='ourproductbannerMobile'>
        <div style={{color:"white", margin:"auto", fontSize:"2.2rem", fontWeight:"bold", zIndex:"2", textAlign:"center"}} >Our Product</div>
    </div>

      <div className="button-container">
        <button className="stylish-button ondo" onClick={handleShowAboutUs}>ONDO</button>
        <button className="stylish-button" onClick={handleShowAboutUss}>orinheal</button>
      </div>

      {showAboutUs && (
        <div className='aboutus'>

          <div className='abouttMobile'>

            <div className='ourprdMobile'>
              <div className='productImg'>
                <LazyLoadImage src={mp1} effect="blur"/>
              </div>
              <div className='productDes'>
                <div className='prd-title'>
                  ONDO SKIN CLOTHO CLEANSER
                </div>
                <div className='prd-cate'>
                  Subacidity / Skin Soothing / Whipped Cream Bubble
                </div>
                <div className='prd-des'>
                  A cleanser that soothes sensitive skin with a pH-weak bubble that makes your skin soft and moist even after washing your face.
                </div>
              </div>
            </div>

            <div className='ourprdMobile'>
              <div className='productImg'>
                <LazyLoadImage src={mp2} effect="blur"/>
              </div>
              <div className='productDes'>
                <div className='prd-title'>
                  ONDO SKIN CLOTHO TONER
                </div>
                <div className='prd-cate'>
                  Soothing / Oil · water Balance / Fresh and Moist
                </div>
                <div className='prd-des'>
                  A soothing toner that helps to control the oil and moisture balance of the skin th soothe sensitive skin.
                </div>
              </div>
            </div>

            <div className='ourprdMobile' style={{marginBottom:"40px"}}>
              <div className='productImg'>
                <LazyLoadImage src={mp3} effect="blur"/>
              </div>
              <div className='productDes'>
                <div className='prd-title'>
                  ONDO SKIN CLOTHO AMPOULE
                </div>
                <div className='prd-cate'>
                  Luminosity / Whitening / Natural Glow
                </div>
                <div className='prd-des'>
                  Highly concentrated triple (Whiening, elastic, glossy) intensive care product for bright and shiny skin
                </div>
              </div>
            </div>

          </div>

          <div className='aboutt'>
            <LazyLoadImage className='abou' src={op1} effect="blur"/>
          </div>
          <div className='aboutt'>
            <LazyLoadImage className='abou' src={op2} effect="blur"/>
          </div>
        </div>
      )}

      {showAboutUss && (
        <div className='aboutuss'>

          <div className='abouttMobile'>

          <div className='ourprdMobile'>
            <div className='productImg'>
              <LazyLoadImage src={mt1} effect="blur"/>
            </div>
            <div className='productDes'>
              <div className='prd-title'>
                The Pure Oil
              </div>
              <div className='prd-cate'>
                Lightweight / Revitalizing / Intense Hydration
              </div>
              <div className='prd-des'>
                Vegan oil containing 10.5% carrot extract derived from Jeju Island, cold-aged for 60 days to ensure fast absorption, delivering instant vitality to the skin.
              </div>
            </div>
          </div>

          <div className='ourprdMobile'>
            <div className='productImg'>
              <LazyLoadImage src={mt2} effect="blur"/>
            </div>
            <div className='productDes'>
              <div className='prd-title'>
                Vegan Hyaluronic Acid Energy Ampoule
              </div>
              <div className='prd-cate'>
                Moisturizing / Skin Firmness / Soothing
              </div>
              <div className='prd-des'>
                Combines hyaluronic acid for hydration and Jeju oat extract for enhanced elasticity, using Skin Fitting Membrane technology to deliver active ingredients deep into the skin.
              </div>
            </div>
          </div>

          <div className='ourprdMobile' style={{marginBottom:"40px"}}>
            <div className='productImg'>
              <LazyLoadImage src={mt3} effect="blur"/>
            </div>
            <div className='productDes'>
              <div className='prd-title'>
                Pure Romance Ampoule
              </div>
              <div className='prd-cate'>
                Moisturizing / Firming / Soothing
              </div>
              <div className='prd-des'>
                Contains 10 types of hyaluronic acid, panthenol, betaine, and beta-glucan to soothe the skin while enhancing hydration and elasticity.
              </div>
            </div>
          </div>

          </div>

          <div className='aboutt'>
            <LazyLoadImage className='abou' src={op3} effect="blur"/>
          </div>
          <div className='aboutt'>
            <LazyLoadImage className='abou' src={op4} effect="blur"/>
          </div>
        </div>
      )}
    </>
  );
}

export default OurProduct;