import React from 'react';
import '../css/common.css';
import '../css/aboutus.css'
import j7 from '../asset/j7.png';
import j8 from '../asset/j8.png';
import j9 from '../asset/j9.png';
import j10 from '../asset/j10.png';
import j11 from '../asset/j11.png';
import j12 from '../asset/j12.png';
import oemodm from '../asset/oemodem.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';


function Process() {
  return (
    <>

<div className='processbanner'>
    <div style={{color:"white", margin:"auto", fontSize:"2rem", fontWeight:"bold", zIndex:"2"}} >OEM / ODM PROCESS</div>
</div>

<div className='processbannerMobile'>
    <div style={{color:"white", margin:"auto", fontSize:"2.2rem", fontWeight:"bold", zIndex:"2", textAlign:"center"}} >OEM / ODM<br/>PROCESS</div>
</div>

<div className='aboutus'>


  <div className='aboutt'>
    <LazyLoadImage className='abou' src={j7} effect="blur"/>
  </div>
  <div className='aboutt'>
    <LazyLoadImage className='abou' src={j8} effect="blur"/>
  </div>
  <div className='aboutt'>
    <LazyLoadImage className='abou' src={j9} effect="blur"/>
  </div>
  <div className='aboutt'>
    <LazyLoadImage className='abou' src={j10} effect="blur"/>
  </div>
  <div className='aboutt'>
    <LazyLoadImage className='abou' src={j11} effect="blur"/>
  </div>
  <div className='aboutt'>
    <LazyLoadImage className='abou' src={j12} effect="blur"/>
  </div>

  <div className='abouttMobile'>
    <LazyLoadImage className='abou' src={oemodm} effect="blur" style={{paddingBottom:"40px", paddingTop:"30px"}}/>
  </div>

</div>
    
   </>
  );
}

export default Process;