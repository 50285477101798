import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { auth, db } from '../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDoc, serverTimestamp  } from 'firebase/firestore';
import userProfile from '../asset/user.png';
import upArrrow from '../asset/up-arrow.png';
import downArrow from '../asset/down-arrow.png';
import close from '../asset/close.png';

import '../css/navigation.css';

function Navigations({userObj}) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [localPhoneNumber, setLocalPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenR, setIsModalOpenR] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAboutUs, setIsAboutUs] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const aboutUs = () => {
    setIsAboutUs(!isAboutUs);
  }

  {/*
  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeColor);
    return () => window.removeEventListener('scroll', changeColor);
  }, []);
  */}

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsLoggedIn(true);

        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserInfo(userSnap.data());
        } else {
          console.log("No user data found!");
        }
      } else {
        setIsLoggedIn(false);
        setUserInfo(null);
      }
    });

    return unsubscribe;
  }, []);


    useEffect(() => {
      if (!isModalOpen) {
        setEmail('');
        setPassword('');
      }
    }, [isModalOpen]);



      const handleSignUp = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
          const userCredential = await createUserWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
    
          const userData = {
            uid: user.uid,
            firstName: firstName,
            lastName: lastName,
            countryCode: countryCode,
            localPhoneNumber: localPhoneNumber,
            email: email,
            createdAt: serverTimestamp(),
            active: true,
          };
    
          await setDoc(doc(db, 'users', user.uid), userData);
          setUserInfo(userData);
          alert('Signed Up Successfully');
          navigate('/');
          window.location.reload();
        } catch (error) {
          alert('Invalid User Info');
          console.log(error.message);
        } finally {
          setLoading(false);
        }
      };

      const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          const user = userCredential.user;
    
          const userRef = doc(db, 'users', user.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            setUserInfo(userSnap.data());
          }
    
          alert('Logged In successfully');
          navigate('/');
          window.location.reload();
        } catch (error) {
          alert('Invalid User Info');
        } finally {
          setLoading(false);
        }
      };

  const handleLogout = () => {
    auth.signOut().then(() => {
      setIsLoggedIn(false);
      alert('Logged out successfully');
      navigate('/');
      window.location.reload();
    }).catch(error => {
      console.error('Error during sign out', error);
      alert('Failed to log out: ' + error.message);
    });
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

    const openModalR = () => setIsModalOpenR(true);
    const closeModalR = () => setIsModalOpenR(false);


    return(
<div className="headerbody">
{loading && (
        <div className="loading">
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
      <div className={color ? 'header header-bg' : 'header'}>
        <div className='header-content'>
          <div className='left-content'>
            <Link to="/"><div className='hLogo'></div></Link>
          </div>
          <div className='center-content'>
          <Link to="/aboutcompany"><span>ABOUT US</span></Link>
            <Link to="/ourproduct"><span>OUR PRODUCT</span></Link>
            <Link to="/process"><span>OEM/ODM PROCESS</span></Link>
            {isLoggedIn ? (
            <Link to="/oeminquiry"><span>INQUIRY</span></Link>
            ) : (
              <Link to="/oeminquirys"><span>INQUIRY</span></Link>
            )
            }
            <Link to="/contactus"><span>CONTACT US</span></Link>
            <Link to="https://darli-ebook.netlify.app/"><span>EBOOK</span></Link>
          </div>
          <div className='right-content'>
            {isLoggedIn ? (
                <>
                  <span onClick={handleLogout}>Logout</span>
                </>
              ) : (
                <>
                <span onClick={openModalR}>Sign Up</span>
                <span onClick={openModal}>Login</span>
                </>
              )}
          </div>

          <div className='hamburger-menu' onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        {isMenuOpen && (
          <div className={`dropdown-menu ${isMenuOpen ? 'show' : ''}`}>
          <div className='dropdown-inner'>
            {isLoggedIn ? (
              <div className='inner-top'>
              <div className='dropdown-bottom'>
                <div>
                <span onClick={() => {handleLogout(); toggleMenu();}} style={{cursor:"pointer"}}>Logout</span>
                </div>
                <div><img className='close' src={close} onClick={toggleMenu}/></div>
              </div>
              <div className='profile-section'>
                <div>
                  <img className='user-profile' src={userProfile}/>
                </div>
                <div>
                {userInfo?.email || "Unknown Email"}<br />
                {userInfo?.firstName || "Unknown"} {userInfo?.lastName || "User"}
                </div>
              </div>
              </div>
            ) : (
              <div className='inner-top'>
              <div className='dropdown-bottom'>
              <div>
              <span onClick={() => {openModalR(); toggleMenu();}} style={{cursor:"pointer"}}>Sign Up</span>
              <span onClick={() => {openModal(); toggleMenu();}} style={{cursor:"pointer"}}>Login</span>
              </div>
              <div><img className='close' src={close} onClick={toggleMenu}/></div>
              </div>
              <div className='profile-section'>
                <div>
                  <img className='user-profile' src={userProfile}/>
                </div>
                <div>
                  None-email
                </div>
              </div>
              </div>
            )}
            <a onClick={aboutUs} className='inner-a'><span>ABOUT US</span><span>{isAboutUs ? <img className='extend' src={downArrow}/> : <img className='extend' src={upArrrow}/>}</span></a>
            {isAboutUs ? 
            <div className='aboutus-menu'>
              <Link to="/aboutusma" onClick={toggleMenu}>Company Introduction</Link>
              <Link to="/aboutusmb" onClick={toggleMenu}>Organizational Chart</Link>
              <Link to="/aboutusmc" onClick={toggleMenu}>Our Strength & Field</Link>
            </div>:''
            }
            <Link to="/ourproduct" onClick={toggleMenu}><span>OUR PRODUCT</span></Link>
            <Link to="/process" onClick={toggleMenu}><span>OEM/ODM PROCESS</span></Link>
            {isLoggedIn ? (
            <Link to="/oeminquiry" onClick={toggleMenu}><span>INQUIRY</span></Link>
            ) : (
              <Link to="/oeminquirys" onClick={toggleMenu}><span>INQUIRY</span></Link>
            )
            }
            <Link to="/contactus" onClick={toggleMenu}><span>CONTACT US</span></Link>
            <Link to="https://darli-ebook.netlify.app/" onClick={toggleMenu}><span>EBOOK</span></Link>
            </div>
          </div>
        )}
      </div>
      {isModalOpenR && (
        <div className='login-modal-overlay' onClick={closeModalR}>
          <div className='login-modal' onClick={(e) => e.stopPropagation()}>
            <form onSubmit={handleSignUp}>
              <div className='login-heading'>SIGN UP</div>
              <div className='register-area'>
                <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder='First Name'
                />
                <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder='Last Name'
                />
              </div>

              <div className='phone-area'>
              <PhoneInput
                country={'us'}
                value={countryCode}
                onChange={(value, country) => setCountryCode(`+${country.dialCode}`)}
                placeholder="Select country code"
              />
              <input
                className='phone'
                type="tel"
                style={{borderRadius: "0px 5px 5px 0px"}}
                value={localPhoneNumber}
                onChange={(e) => setLocalPhoneNumber(e.target.value)}
                placeholder="Enter local phone number"
              />
              </div>

              <div className='register-area'>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
              />
              </div>

              <div className='register-area' style={{display:"inline-block"}}>
                <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Password'
                />
                <div style={{fontSize:"0.8rem", color:"#ccc"}}>password must contain a combination of letters and numbers</div>
              </div>

              <div className='register-area'>
                <button type="submit">Sign Up</button>
              </div>
            </form>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className='login-modal-overlay' onClick={closeModal}>
          <div className='login-modal' onClick={(e) => e.stopPropagation()}>
            <form className='signform' onSubmit={handleLogin}>
              <div className='login-heading'>LOGIN</div>
              <div className='register-area'>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email Address"
              />
              </div>

              <div className='register-area'>
                <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder='Password'
                />
              </div>

              <div className='register-area'>
                <button type="submit">Log In</button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
    )
}

export default Navigations;