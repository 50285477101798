import React from 'react';
import '../css/common.css'
import '../css/aboutus.css'
import aboutusmb from '../asset/aboutusmb.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function AboutUsMB() {
  return (
    <>

<div className='aboutbannerMobile'>
    <div style={{color:"white", margin:"auto", fontSize:"2.2rem", fontWeight:"bold", zIndex:"2"}} >ABOUT US</div>
</div>
<div className='aboutus'>

<div className='aboutus-title'>
    <h1><strong>ORGANIZATIONAL<br/>CHART</strong></h1>
  </div>

  <div className='abouttMobile' style={{paddingBottom:"40px"}}>
    <LazyLoadImage className='abou' src={aboutusmb} effect="blur"/>
  </div>

</div>
    
   </>
  );
}

export default AboutUsMB;