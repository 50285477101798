import React from 'react';
import '../css/common.css'
import '../css/aboutus.css'
import aboutusmca from '../asset/aboutusmca.png';
import aboutusmcb from '../asset/aboutusmcb.png';
import aboutusmcc from '../asset/aboutusmcc.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function AboutUsMC() {
  return (
    <>

<div className='aboutbannerMobile'>
    <div style={{color:"white", margin:"auto", fontSize:"2.2rem", fontWeight:"bold", zIndex:"2"}} >ABOUT US</div>
</div>
<div className='aboutus'>

<div className='aboutus-title'>
    <h1 style={{fontSize:"1.8rem"}}><strong>OUR<br/>STRENGTH & FIELD</strong></h1>
  </div>

  <div className='abouttMobile'>
    <LazyLoadImage className='abou' src={aboutusmca} effect="blur"/>
  </div>

  <div className='aboutus-content' style={{padding:"30px 10px 0px 10px"}}>
  <h3>OUR FIELD PRIVATE LABEL COSMETICS</h3>  
  </div>
  <div className='abouttMobile'>
    <LazyLoadImage className='abou' src={aboutusmcb} effect="blur"/>
  </div>
  <div className='aboutus-content'>
  <p>Our Customer can launch and sell your own cosmetic brand producdts which can be manufactured with the best service and quality.</p>  
  </div>
      
  <div className='aboutus-content' style={{padding:"0px 10px 0px 10px"}}>
  <h3>OVERSEAS MARKET ANALYSIS & FORECAST</h3>  
  </div>
  <div className='abouttMobile' style={{paddingBottom:"40px"}}>
    <LazyLoadImage className='abou' src={aboutusmcc} effect="blur"/>
  </div>

</div>
    
   </>
  );
}

export default AboutUsMC;