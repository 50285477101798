import React, { useState, useEffect } from 'react';
import '../../css/bestseller.css';
import c1 from '../../asset/c1.jpg';
import c2 from '../../asset/c2.jpg';
import c3 from '../../asset/c3.jpg';
import c4 from '../../asset/c4.jpg';
import c5 from '../../asset/c5.jpg';
import c6 from '../../asset/c6.jpg';
import c7 from '../../asset/c7.jpg';
import c8 from '../../asset/c8.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function BestSeller() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const slider1Images = [
        [c1, c2], 
        [c5, c6]  
    ];

    const slider2Images = [
        [c3, c4], 
        [c7, c8]  
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false
    };

    return (
        <section>
            <div className='main3'>
                <div className='main3-inner'>
                    <div className='main3-heading'>
                        <h1>FEATURED PRODUCTS</h1>
                    </div>

                    {/* 768px 이상: 기존 그리드 표시 */}
                    {!isMobile ? (
                        <div className='main3-grid-container'>
                            <div className='main3-grid-item'>
                                <LazyLoadImage className='main3-item' src={c1} effect="blur"/>
                            </div>
                            <div className='main3-grid-item'>
                                <LazyLoadImage className='main3-item' src={c2} effect="blur"/>
                            </div>
                            <div className='main3-grid-item'>
                                <LazyLoadImage className='main3-item' src={c3} effect="blur"/>
                            </div>
                            <div className='main3-grid-item'>
                                <LazyLoadImage className='main3-item' src={c4} effect="blur"/>
                            </div>
                            <div className='main3-grid-item'>
                                <LazyLoadImage className='main3-item' src={c5} effect="blur"/>
                            </div>
                            <div className='main3-grid-item'>
                                <LazyLoadImage className='main3-item' src={c6} effect="blur"/>
                            </div>
                            <div className='main3-grid-item'>
                                <LazyLoadImage className='main3-item' src={c7} effect="blur"/>
                            </div>
                            <div className='main3-grid-item'>
                                <LazyLoadImage className='main3-item' src={c8} effect="blur"/>
                            </div>
                        </div>
                    ) : (
                        // 768px 이하: 슬라이드 표시
                        <div className="main3-slider-container">
                            {/* 첫 번째 슬라이드 (c1~c2 -> c5~c6) */}
                            <Slider {...settings} className="main3-slider">
                                {slider1Images.map((slide, index) => (
                                    <div key={index} className="main3-slider-item">
                                        {slide.map((image, idx) => (
                                            <LazyLoadImage key={idx} className='main3-item' src={image} effect="blur" style={{display:"flex"}}/>
                                        ))}
                                    </div>
                                ))}
                            </Slider>

                            {/* 두 번째 슬라이드 (c3~c4 -> c7~c8) */}
                            <Slider {...settings} className="main3-slider">
                                {slider2Images.map((slide, index) => (
                                    <div key={index} className="main3-slider-item">
                                        {slide.map((image, idx) => (
                                            <LazyLoadImage key={idx} className='main3-item' src={image} effect="blur"/>
                                        ))}
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}

export default BestSeller;